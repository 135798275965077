$mainColor: #330033;
$secColor: #602A60;

@import "_backoffice-v2";

#main aside .menu-heading {
  background-image: url("/assets/images/m/backoffice/header-logo.png");
  background-repeat: no-repeat;
  background-position: center center;
  height: 100px;
  background-size: 180px;
}
